document.addEventListener('DOMContentLoaded', function () {
  // if ('serviceWorker' in navigator) {
  //   navigator.serviceWorker.register(
  //     new URL('service-worker.js', import.meta.url),
  //     { type: 'module' }
  //   )
  // }

  const scrollWrap = document.getElementsByClassName('gyroscope-effect')[0]

  //
  // Add gyroscope effect
  //
  window.addEventListener('deviceorientation', event => {
    const deg = ((Math.abs(event.beta) - 45) / 20)
    scrollWrap.style.transform = 'perspective(100px) rotateX(' + deg + 'deg) '
  })

  //
  // Animate progress bars
  //
  const animationElements = document.querySelectorAll("[class*='a-']")
  const progressBars = debounce(function () {
    animationElements.forEach(element => {
      const windowHeight = window.innerHeight
      const windowTopPosition = window.scrollX
      const windowBottomPosition = (windowTopPosition + windowHeight)
      const elementRect = element.getClientRects()[0]
      const elementBottomPosition = (elementRect.top + elementRect.height)

      // Check to see if this current container is within viewport
      if ((elementBottomPosition >= windowTopPosition) && (elementRect.top <= windowBottomPosition)) {
        element.classList.add('in-view')
        // Animate progress bar
        if (element.classList.contains('a-progress-bar')) {
          element.style.width = element.dataset.percent + '%'
        }
      }
    })
  }, 100, true)
  progressBars()
  document.addEventListener('scroll', progressBars)
  scrollWrap.addEventListener('scroll', progressBars)
  scrollWrap.addEventListener('resize', progressBars)
})

// Simple debouncer
function debounce (func, wait, immediate) {
  let timeout
  return function () {
    const context = this
    const args = arguments
    const later = function () {
      timeout = null
      if (!immediate) func.apply(context, args)
    }
    const callNow = immediate && !timeout
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
    if (callNow) func.apply(context, args)
  }
}
